<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="customer-setting-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Customer/List</h4>
        </div>
            <div class="accordion" id="customerAccordion">

              <div class="card">
                <div class="card-header" id="settingCustomerReferralCodePrefixHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingCustomerReferralCodePrefixCollapse" aria-expanded="true" aria-controls="collapseCustomerReferralCodePrefix">
                    <span class="text-capitalize customer-setting-title">Customer Referral Code Prefix</span>
                  </button>
                </div>
                <div id="settingCustomerReferralCodePrefixCollapse" class="collapse" aria-labelledby="settingCustomerReferralCodePrefixHeading" data-parent="#customerAccordion">
                  <div class="card-body">
                    <SettingCustomerReferralCodePrefix :customer-referral-code-prefix="customerReferralCodePrefix" />
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingCustomerReferralCodeSuffixHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingCustomerReferralCodeSuffixCollapse" aria-expanded="true" aria-controls="collapseCustomerReferralCodeSuffix">
                    <span class="text-capitalize customer-setting-title">Customer Referral Code Suffix</span>
                  </button>
                </div>
                <div id="settingCustomerReferralCodeSuffixCollapse" class="collapse" aria-labelledby="settingCustomerReferralCodeSuffixHeading" data-parent="#customerAccordion">
                  <div class="card-body">
                    <SettingCustomerReferralCodeSuffix :customer-referral-code-suffix="customerReferralCodeSuffix" />
                  </div>
                </div>
              </div>

            </div>

      </div>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingCustomerReferralCodePrefix from "@/views/backEnd/settings/customer/includes/SettingCustomerReferralCodePrefix";
import SettingCustomerReferralCodeSuffix from "@/views/backEnd/settings/customer/includes/SettingCustomerReferralCodeSuffix";

export default {
  name: "SettingCustomerList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingCustomerReferralCodePrefix,
    SettingCustomerReferralCodeSuffix,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["customer"],
        key: [
          "customer_referral_code_prefix",
          "customer_referral_code_suffix",
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      customerReferralCodePrefix: 'appSettings/settingCustomerReferralCodePrefix',
      customerReferralCodeSuffix: 'appSettings/settingCustomerReferralCodeSuffix',

    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.customer-setting-wrapper .accordion .card{
  overflow: visible !important;
}
.customer-setting-title{
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
